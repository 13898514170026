import { display } from "./inactivityPrompt.js";
import { cookieValue } from "./utilities.js";

const inactivityCookieName = 'stInactivity';
const logOutUrl = '/account/logout?timeOut=true';
const lastActivityKey = 'lastActivityTime';
const broadcastChannelName = 'inactivityBroadcastChannel';
const promptBroadcastKey = 'promptBroadcast';
const logOutBroadcastKey = 'logOutBroadcast';

const promptPeriodMinutes = cookieValue(inactivityCookieName);
const logOutPeriodMinutes = 0.5;

const minutesToMiliseconds = (minutes) => minutes * 60 * 1000;
const promptPeriodMiliseconds = minutesToMiliseconds(promptPeriodMinutes);
const logOutPeriodMiliseconds = minutesToMiliseconds(logOutPeriodMinutes);

let timer;

const broadcastChannel = new BroadcastChannel(broadcastChannelName);
const setLastActivity = () => {
	clearTimeout(timer);
	timer = setTimeout(checkForUserPrompt, promptPeriodMiliseconds);
	localStorage.setItem(lastActivityKey, Date.now());
}

const checkForUserPrompt = () => {
	var lastActivity = localStorage.getItem(lastActivityKey);
	if (lastActivity && (Date.now() - lastActivity) >= promptPeriodMiliseconds) {
		promptUser();
		broadcastChannel.postMessage(promptBroadcastKey)
	}
}

const promptUser = () => {
	display();
	timer = setTimeout(checkForLogOut, logOutPeriodMiliseconds);
}

const checkForLogOut = () => {
	const lastActivity = localStorage.getItem(lastActivityKey);
	if (lastActivity && (Date.now() - lastActivity) >= promptPeriodMiliseconds + logOutPeriodMiliseconds) {
		logOut();
		broadcastChannel.postMessage(logOutBroadcastKey)
	}
}

const logOut = () => {
	window.location.href = logOutUrl;
}

const resetTimerOnInteraction = () => window.site.doc.on('mousemove keydown scroll click', setLastActivity);
const resetTimerOnClick = () => window.site.doc.on('click', setLastActivity);
const removeAll = () => window.site.doc.off('mousemove keydown scroll click');

const init = () => {

	// If page has timed out and reloaded with the popup message visible (from Partials/InactivityLogOutMessage.cshtml)
	// this popup needs to be closed
	$('body').one('click', function() {
		$('.m-popup-new, .popupFade-new').remove()
	});

	if (cookieValue(inactivityCookieName) != null) {
		setLastActivity();
		resetTimerOnInteraction();

		broadcastChannel.onmessage = function(event) {
			if (event.data === logOutBroadcastKey) {
				logOut();
			} else if (event.data === promptBroadcastKey) {
				promptUser();
			}
		};
	}
}

export default init;
export { resetTimerOnClick, removeAll, resetTimerOnInteraction }
